// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import SmileOutlined from '/home/johnson/lulu-hk-staff-frontend-web/node_modules/@ant-design/icons/es/icons/SmileOutlined';
import HomeOutlined from '/home/johnson/lulu-hk-staff-frontend-web/node_modules/@ant-design/icons/es/icons/HomeOutlined';
import QuestionOutlined from '/home/johnson/lulu-hk-staff-frontend-web/node_modules/@ant-design/icons/es/icons/QuestionOutlined';
import CrownOutlined from '/home/johnson/lulu-hk-staff-frontend-web/node_modules/@ant-design/icons/es/icons/CrownOutlined';
import FolderOutlined from '/home/johnson/lulu-hk-staff-frontend-web/node_modules/@ant-design/icons/es/icons/FolderOutlined';
import TagOutlined from '/home/johnson/lulu-hk-staff-frontend-web/node_modules/@ant-design/icons/es/icons/TagOutlined';
import DollarOutlined from '/home/johnson/lulu-hk-staff-frontend-web/node_modules/@ant-design/icons/es/icons/DollarOutlined';
import CloudOutlined from '/home/johnson/lulu-hk-staff-frontend-web/node_modules/@ant-design/icons/es/icons/CloudOutlined';
import UserOutlined from '/home/johnson/lulu-hk-staff-frontend-web/node_modules/@ant-design/icons/es/icons/UserOutlined';
export default { SmileOutlined, HomeOutlined, QuestionOutlined, CrownOutlined, FolderOutlined, TagOutlined, DollarOutlined, CloudOutlined, UserOutlined };

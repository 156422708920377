import component from './zh-CN/component';
import globalHeader from './zh-CN/globalHeader';
import menu from './zh-CN/menu';
import pages from './zh-CN/pages';
import pwa from './zh-CN/pwa';
import settingDrawer from './zh-CN/settingDrawer';
import settings from './zh-CN/settings';

export default {
  'navBar.lang': '语言',
  'layout.user.link.help': '帮助',
  'layout.user.link.privacy': '隐私',
  'layout.user.link.terms': '条款',
  'app.preview.down.block': '下载此页面到本地项目',
  ...pages,
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
  'app.name': '路路香港员工',
  'tmp.language.english': 'English',
  'tmp.language.zh_Hans': '简体中文',
  'tmp.language.zh_Hant': '繁體中文',
  'tmp.welcome': '欢迎！',
  'tmp.dashboard': '概览',
  'tmp.captcha.title': '安全检查（点按图片来换个验证码）',
  'tmp.captcha.placeholder': '安全检查',
  'tmp.captcha.hint': '请输入验证码',
  'tmp.captcha.change.fail': '无法生成新的验证码',
  'tmp.login.success': '登录成功',
  'tmp.login.fail': '登录失败',
  'tmp.logout': '退出登录',
  'tmp.clear': '清除',
  'tmp.confirm': '确认',
  'tmp.okay': '确定',
  'tmp.bracket-open': '（',
  'tmp.bracket-close': '）',
  'tmp.no-permission': '没有权限',
  'tmp.upload-title': '上传',
  'tmp.upload-fail': "上传失败：",
  'tmp.upload-error-unexpected': "发生意外错误",
  'tmp.upload-success': " 上传成功",
  'tmp.update-success': "更新成功！",
  'tmp.password-change.success': "修改成功！",
  'tmp.password-change.fail': "无法修改密码",
  'tmp.password-change.new-not-match': "新密码不匹配",
  'tmp.password-change.old-password.placeholder': "当前密码",
  'tmp.password-change.new-password.placeholder': "新密码",
  'tmp.password-change.confirm-new-password.placeholder': "确认新密码",
  'tmp.password-change.must-fill': "此为必填项",
  'tmp.password-change.merchant-password-invalid': "密码必须至少有8个字符，而且至少包含以下其中两种字符：大写字母、小写字母、数字和符号。",
  'tmp.password-change.password-same-as-old': "新密码不能和当前密码相同",
  'tmp.password-change.wrong-password': "密码错误",
  'tmp.timepostedon': "发布在",
  'tmp.accessTokenExpired': "登录已过期，请重新登录。",
  'tmp.accessTokenExpiredTitle': "登录已过期",
  'tmp.pageNotFound': "抱歉，找不到网页",
  'tmp.goBackHome': "返回首页",
};

export default {
  'menu.welcome': 'Welcome',
  'menu.more-blocks': 'More Blocks',
  'menu.home': 'Home',
  'menu.admin': 'Admin',
  'menu.admin.sub-page': 'Sub-Page',
  'menu.login': 'Login',
  'menu.register': 'Register',
  'menu.register-result': 'Register Result',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': 'Analysis',
  'menu.dashboard.monitor': 'Monitor',
  'menu.dashboard.workplace': 'Workplace',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Form',
  'menu.form.basic-form': 'Basic Form',
  'menu.form.step-form': 'Step Form',
  'menu.form.step-form.info': 'Step Form(write transfer information)',
  'menu.form.step-form.confirm': 'Step Form(confirm transfer information)',
  'menu.form.step-form.result': 'Step Form(finished)',
  'menu.form.advanced-form': 'Advanced Form',
  'menu.list': 'List',
  'menu.list.table-list': 'Search Table',
  'menu.list.basic-list': 'Basic List',
  'menu.list.card-list': 'Card List',
  'menu.list.search-list': 'Search List',
  'menu.list.search-list.articles': 'Search List(articles)',
  'menu.list.search-list.projects': 'Search List(projects)',
  'menu.list.search-list.applications': 'Search List(applications)',
  'menu.profile': 'Profile',
  'menu.profile.basic': 'Basic Profile',
  'menu.profile.advanced': 'Advanced Profile',
  'menu.result': 'Result',
  'menu.result.success': 'Success',
  'menu.result.fail': 'Fail',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Account',
  'menu.account.center': 'Account Center',
  'menu.account.settings': 'Account Settings',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.logout': 'Logout',
  'menu.editor': 'Graphic Editor',
  'menu.editor.flow': 'Flow Editor',
  'menu.editor.mind': 'Mind Editor',
  'menu.editor.koni': 'Koni Editor',
  'menu.enquiry-apply': 'Enquiry / Apply',
  'menu.enquiry-apply.merchant-apply': 'Merchant Apply',
  'menu.enquiry-apply.merchant-issue': 'Merchant Enquiry',
  'menu.enquiry-apply.shop-apply': 'Shop Apply',
  'menu.merchant-shop': 'Merchant / Shop',
  'menu.merchant-shop.merchant-add-edit': 'Add / Edit Merchant',
  'menu.merchant-shop.shop-add-edit': 'Add / Edit Shop',
  'menu.merchant-shop.product-add-edit': 'Add / Edit Product',
  'menu.payment-add-edit': 'Add / Edit Payment',
  'menu.tag-add-edit': 'Add / Edit Tag',
  'menu.tag-add-edit.merchant-tag': 'Merchant Tag',
  'menu.tag-add-edit.shop-tag': 'Shop Tag',
  'menu.tag-add-edit.product-tag': 'Product Tag',
  'menu.category-add-edit': 'Add / Edit Category',
  'menu.category-add-edit.merchant-category': 'Merchant Category',
  'menu.category-add-edit.shop-category': 'Shop Category',
  'menu.category-add-edit.product-category': 'Product Category',
  'menu.award-add-edit': 'Add / Edit Award',
  'menu.award-add-edit.merchant-award': 'Merchant Award',
  'menu.award-add-edit.shop-award': 'Shop Award',
  'menu.award-add-edit.product-award': 'Product Award',
  'menu.system': 'System',
  'menu.system.message-edit': 'Edit Message',
  'menu.system.version-edit': 'Edit Version',
  'menu.staff-account': 'Account',
  'menu.staff-account.password-change': 'Change Password',
  'menu.staff-account.staff-add-edit': 'Add / Edit Staff',
};

import { deviceHeaders, getLocale  } from '@/utils/utils';
import { request } from 'umi';

export interface AccountLoginParamsType {
  username: string;
  password: string;
}

export async function accountLogin(params: AccountLoginParamsType, captcha?: API.Captcha) {
  let headers = deviceHeaders();

  if (captcha) {
    headers.captchaID = captcha.captchaID;
    headers.captcha = captcha.captcha;
  }
  return request('/api/account/login', {
    method: 'POST',
    data: params,
    headers: headers,
    params: {
      locale: getLocale(),
    },
  });
}

export async function accountAutoLogin(params: AccountLoginParamsType) {
  return request('/api/account/login-auto', {
    headers: deviceHeaders(),
    params: {
      locale: getLocale(),
    },
  });
}

export async function accountLogout() {
  return request('/api/account/logout', {
    headers: deviceHeaders(),
    params: {
      locale: getLocale(),
    },
  });
}

export async function accountInfo() {
  return request('/api/account/info', {
    params: {
      locale: getLocale(),
    },
  });
}

export async function refreshCaptcha() {
  return request('/api/captcha', {
    params: {
      locale: getLocale(),
    },
  });
}
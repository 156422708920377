export default {
  'menu.welcome': '欢迎',
  'menu.more-blocks': '更多区块',
  'menu.home': '首页',
  'menu.admin': '管理页',
  'menu.admin.sub-page': '二级管理页',
  'menu.login': '登录',
  'menu.register': '注册',
  'menu.register-result': '注册结果',
  'menu.dashboard': '概览',
  'menu.dashboard.analysis': '分析页',
  'menu.dashboard.monitor': '监控页',
  'menu.dashboard.workplace': '工作台',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': '表单页',
  'menu.form.basic-form': '基础表单',
  'menu.form.step-form': '分步表单',
  'menu.form.step-form.info': '分步表单（填写转账信息）',
  'menu.form.step-form.confirm': '分步表单（确认转账信息）',
  'menu.form.step-form.result': '分步表单（完成）',
  'menu.form.advanced-form': '高级表单',
  'menu.list': '列表页',
  'menu.list.table-list': '查询表格',
  'menu.list.basic-list': '标准列表',
  'menu.list.card-list': '卡片列表',
  'menu.list.search-list': '搜索列表',
  'menu.list.search-list.articles': '搜索列表（文章）',
  'menu.list.search-list.projects': '搜索列表（项目）',
  'menu.list.search-list.applications': '搜索列表（应用）',
  'menu.profile': '详情页',
  'menu.profile.basic': '基础详情页',
  'menu.profile.advanced': '高级详情页',
  'menu.result': '结果页',
  'menu.result.success': '成功页',
  'menu.result.fail': '失败页',
  'menu.exception': '异常页',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': '触发错误',
  'menu.account': '个人页',
  'menu.account.center': '个人中心',
  'menu.account.settings': '个人设置',
  'menu.account.trigger': '触发报错',
  'menu.account.logout': '退出登录',
  'menu.editor': '图形编辑器',
  'menu.editor.flow': '流程编辑器',
  'menu.editor.mind': '脑图编辑器',
  'menu.editor.koni': '拓扑编辑器',
  'menu.enquiry-apply': '反馈 / 注册',
  'menu.enquiry-apply.merchant-apply': '商家注册',
  'menu.enquiry-apply.merchant-issue': '商家反馈',
  'menu.enquiry-apply.shop-apply': '商店注册',
  'menu.merchant-shop': '商家 / 商店',
  'menu.merchant-shop.merchant-add-edit': '新增 / 编辑商家',
  'menu.merchant-shop.shop-add-edit': '新增 / 编辑商店',
  'menu.merchant-shop.product-add-edit': '新增 / 编辑商品',
  'menu.payment-add-edit': '新增 / 编辑付款方式',
  'menu.tag-add-edit': '新增 / 编辑标签',
  'menu.tag-add-edit.merchant-tag': '商家标签',
  'menu.tag-add-edit.shop-tag': '商店标签',
  'menu.tag-add-edit.product-tag': '商品标签',
  'menu.category-add-edit': '新增 / 编辑分类',
  'menu.category-add-edit.merchant-category': '商家分类',
  'menu.category-add-edit.shop-category': '商店分类',
  'menu.category-add-edit.product-category': '商品分类',
  'menu.award-add-edit': '新增 / 编辑奖项',
  'menu.award-add-edit.merchant-award': '商家奖项',
  'menu.award-add-edit.shop-award': '商店奖项',
  'menu.award-add-edit.product-award': '商品奖项',
  'menu.system': '系统',
  'menu.system.message-edit': '编辑推送',
  'menu.system.version-edit': '编辑版本',
  'menu.staff-account': '个人',
  'menu.staff-account.password-change': '修改密码',
  'menu.staff-account.staff-add-edit': '新增 / 编辑员工',
};

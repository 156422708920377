import { SelectLang as UmiSelectLang } from '@umijs/max';
import React from 'react';
import { Switch } from 'antd';
import { useModel } from 'umi';

export type SiderTheme = 'light' | 'dark';

export const SelectLang = () => {
  return (
    <UmiSelectLang
      style={{
        padding: 4,
      }}
    />
  );
};

export const SelectMode = () => {
  const { initialState, setInitialState } = useModel('@@initialState');

  const toggleTheme = (checked: boolean) => {
    const theme = checked ? 'realDark' : 'light';
    setInitialState((s) => ({
      ...s,
      settings: { ...s?.settings, navTheme: theme },
    }));
    localStorage.setItem('navTheme', theme);
  };

  const isDarkMode = initialState?.settings?.navTheme === 'realDark';

  return (
    <div className="right-content">
      <Switch
        checked={isDarkMode}
        onChange={toggleTheme}
        checkedChildren="🌙"
        unCheckedChildren="☀️"
        style={{ marginRight: 10 }}
      />
    </div>
  );
};
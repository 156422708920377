import component from './en-US/component';
import globalHeader from './en-US/globalHeader';
import menu from './en-US/menu';
import pages from './en-US/pages';
import pwa from './en-US/pwa';
import settingDrawer from './en-US/settingDrawer';
import settings from './en-US/settings';

export default {
  'navBar.lang': 'Languages',
  'layout.user.link.help': 'Help',
  'layout.user.link.privacy': 'Privacy',
  'layout.user.link.terms': 'Terms',
  'app.preview.down.block': 'Download this page to your local project',
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
  ...pages,
  'app.name': 'Lulu HK Staff',
  'tmp.language.english': 'English',
  'tmp.language.zh_Hans': '简体中文',
  'tmp.language.zh_Hant': '繁體中文',
  'tmp.welcome': 'Welcome!',
  'tmp.dashboard': 'Dashboard',
  'tmp.captcha.title': 'Security Check (Click on the image to try another code)',
  'tmp.captcha.placeholder': 'Security Check',
  'tmp.captcha.hint': 'Please input the code',
  'tmp.captcha.change.fail': 'Failed to generate new CAPTCHA',
  'tmp.login.success': 'Login Successful',
  'tmp.login.fail': 'Login Failed',
  'tmp.logout': 'Log Out',
  'tmp.clear': 'Clear',
  'tmp.confirm': 'Confirm',
  'tmp.okay': 'OK',
  'tmp.bracket-open': ' (',
  'tmp.bracket-close': ') ',
  'tmp.no-permission': 'No permission',
  'tmp.upload-title': 'Upload',
  'tmp.upload-fail': "Upload failed: ",
  'tmp.upload-error-unexpected': "an unexpected error occurred",
  'tmp.upload-success': " uploaded successfully",
  'tmp.update-success': "Updated successfully!",
  'tmp.password-change.success': "Changed successfully!",
  'tmp.password-change.fail': "Failed to change password",
  'tmp.password-change.new-not-match': "New passwords don't match",
  'tmp.password-change.old-password.placeholder': "Current Password",
  'tmp.password-change.new-password.placeholder': "New Password",
  'tmp.password-change.confirm-new-password.placeholder': "Confirm New Password",
  'tmp.password-change.must-fill': "This is required",
  'tmp.password-change.merchant-password-invalid': "Password must have at least 8 characters and contain at least two of the following: uppercase letters, lowercase letters, numbers, and symbols.",
  'tmp.password-change.password-same-as-old': "New Password cannot be the same as Current Password",
  'tmp.password-change.wrong-password': "Wrong Password",
  'tmp.timepostedon': "Posted on",
  'tmp.accessTokenExpired': "Session expired. Please log in again.",
  'tmp.accessTokenExpiredTitle': "Session expired",
  'tmp.pageNotFound': "Oops! Page not found.",
  'tmp.goBackHome': "Go back home",
};

import component from './zh-TW/component';
import globalHeader from './zh-TW/globalHeader';
import menu from './zh-TW/menu';
import pages from './zh-TW/pages';
import pwa from './zh-TW/pwa';
import settingDrawer from './zh-TW/settingDrawer';
import settings from './zh-TW/settings';

export default {
  'navBar.lang': '語言',
  'layout.user.link.help': '幫助',
  'layout.user.link.privacy': '隱私',
  'layout.user.link.terms': '條款',
  'app.preview.down.block': '下載此頁面到本地項目',
  ...pages,
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
  'app.name': '路路香港員工',
  'tmp.language.english': 'English',
  'tmp.language.zh_Hans': '简体中文',
  'tmp.language.zh_Hant': '繁體中文',
  'tmp.welcome': '歡迎！',
  'tmp.dashboard': '概覽',
  'tmp.captcha.title': '安全檢查（點按圖片來換個驗證碼）',
  'tmp.captcha.placeholder': '安全檢查',
  'tmp.captcha.hint': '請輸入驗證碼',
  'tmp.captcha.change.fail': '無法產生新的驗證碼',
  'tmp.login.success': '登入成功',
  'tmp.login.fail': '登入失敗',
  'tmp.logout': '登出',
  'tmp.clear': '清除',
  'tmp.confirm': '確認',
  'tmp.okay': '確定',
  'tmp.bracket-open': '（',
  'tmp.bracket-close': '）',
  'tmp.no-permission': '沒有權限',
  'tmp.upload-title': '上傳',
  'tmp.upload-fail': "上傳失敗：",
  'tmp.upload-error-unexpected': "發生意外錯誤",
  'tmp.upload-success': " 上傳成功",
  'tmp.update-success': "更新成功！",
  'tmp.password-change.success': "修改成功！",
  'tmp.password-change.fail': "無法修改密碼",
  'tmp.password-change.new-not-match': "新密碼不一致",
  'tmp.password-change.old-password.placeholder': "目前的密碼",
  'tmp.password-change.new-password.placeholder': "新密碼",
  'tmp.password-change.confirm-new-password.placeholder': "確認新密碼",
  'tmp.password-change.must-fill': "此為必填項目",
  'tmp.password-change.merchant-password-invalid': "密碼必須至少有8個字元，而且至少包含以下其中兩種字元：大寫字母、小寫字母、數字和符號。",
  'tmp.password-change.password-same-as-old': "新密碼不能和目前的密碼相同",
  'tmp.password-change.wrong-password': "密碼錯誤",
  'tmp.timepostedon': "發佈於",
  'tmp.accessTokenExpired': "登入已過期，請重新登入。",
  'tmp.accessTokenExpiredTitle': "登入已過期",
  'tmp.pageNotFound': "抱歉，找不到網頁",
  'tmp.goBackHome': "返回首頁",
};

export default {
  'menu.welcome': '歡迎',
  'menu.more-blocks': '更多區塊',
  'menu.home': '首頁',
  'menu.admin': '管理頁面',
  'menu.admin.sub-page': '二級管理頁面',
  'menu.login': '登入',
  'menu.register': '註冊',
  'menu.register-result': '註冊結果',
  'menu.dashboard': '概覽',
  'menu.dashboard.analysis': '分析頁面',
  'menu.dashboard.monitor': '監控頁面',
  'menu.dashboard.workplace': '工作頁面',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': '表格頁面',
  'menu.form.basic-form': '標準表格',
  'menu.form.step-form': '多步表格',
  'menu.form.step-form.info': '多步表格（填寫轉賬資料）',
  'menu.form.step-form.confirm': '多步表格（確認轉賬資料）',
  'menu.form.step-form.result': '多步表格（完成）',
  'menu.form.advanced-form': '高級表格',
  'menu.list': '清單頁面',
  'menu.list.table-list': '查詢表格',
  'menu.list.basic-list': '標準清單',
  'menu.list.card-list': '卡片清單',
  'menu.list.search-list': '搜尋清單',
  'menu.list.search-list.articles': '搜尋清單（文章）',
  'menu.list.search-list.projects': '搜尋清單（項目）',
  'menu.list.search-list.applications': '搜尋清單（應用）',
  'menu.profile': '詳情頁面',
  'menu.profile.basic': '標準詳情頁面',
  'menu.profile.advanced': '高級詳情頁面',
  'menu.result': '結果頁面',
  'menu.result.success': '成功頁面',
  'menu.result.fail': '失敗頁面',
  'menu.exception': '異常頁面',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': '觸發報錯',
  'menu.account': '個人頁面',
  'menu.account.center': '個人中心',
  'menu.account.settings': '個人設定',
  'menu.account.trigger': '觸發報錯',
  'menu.account.logout': '登出',
  'menu.editor': '圖形編輯工具',
  'menu.editor.flow': '流程編輯工具',
  'menu.editor.mind': '腦圖編輯工具',
  'menu.editor.koni': '拓撲編輯工具',
  'menu.enquiry-apply': '反饋 / 註冊',
  'menu.enquiry-apply.merchant-apply': '商家註冊',
  'menu.enquiry-apply.merchant-issue': '商家反饋',
  'menu.enquiry-apply.shop-apply': '商店註冊',
  'menu.merchant-shop': '商家 / 商店',
  'menu.merchant-shop.merchant-add-edit': '新增 / 編輯商家',
  'menu.merchant-shop.shop-add-edit': '新增 / 編輯商店',
  'menu.merchant-shop.product-add-edit': '新增 / 編輯產品',
  'menu.payment-add-edit': '新增 / 編輯付款方式',
  'menu.tag-add-edit': '新增 / 編輯標籤',
  'menu.tag-add-edit.merchant-tag': '商家標籤',
  'menu.tag-add-edit.shop-tag': '商店標籤',
  'menu.tag-add-edit.product-tag': '產品標籤',
  'menu.category-add-edit': '新增 / 編輯分類',
  'menu.category-add-edit.merchant-category': '商家分類',
  'menu.category-add-edit.shop-category': '商店分類',
  'menu.category-add-edit.product-category': '產品分類',
  'menu.award-add-edit': '新增 / 编辑獎項',
  'menu.award-add-edit.merchant-award': '商家獎項',
  'menu.award-add-edit.shop-award': '商店獎項',
  'menu.award-add-edit.product-award': '商品獎項',
  'menu.system': '系統',
  'menu.system.message-edit': '編輯信息',
  'menu.system.version-edit': '編輯版本',
  'menu.staff-account': '個人',
  'menu.staff-account.password-change': '修改密碼',
  'menu.staff-account.staff-add-edit': '新增 / 編輯員工',
};
